<template>
  <div class="cont">
    <Resume :dataList="collectionList"/>
    <el-empty style="padding: 60px 0 300px" v-if="!total" :image-size="100"></el-empty>
    <!-- <el-footer class="listPage" v-if="total > 0">
      <el-pagination
        :total="total"
        :current-page="search.pageNum"
        :page-size="search.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="getcollection"
        @current-change="getcollection"
      ></el-pagination>
    </el-footer> -->
  </div>
</template>
<script>
import Resume from '@/components/Resume'
import { collection } from '@/api/zp'
export default {
  name: 'Collection',
  data() {
    return {
      search: {
        pageNum: 1,
        pageSize: 10
      },
      collectionList: [],
      total: 0
    };
  },
  components: {
    Resume
  },
  created(){
    this.getcollection()
  },
  methods: {
    // 获取收藏
    getcollection() {
      collection(this.search).then(res => {
        this.collectionList = res.rows
        this.total = res.total
      })
    }
  },
};
</script>
<style scoped lang="scss">
.cont{
  min-height: 500px;
  overflow: hidden;
  margin-right: -20px;
  /deep/ .item{
    width: 480px!important;
  }
}
</style>
